import { useEffect } from 'react';
import { isSSR } from '~/shared/utils/platform/utils/platform';
import { useCookieConsentState } from './useCookieConsentState';

export type CookieInformationConsents = {
	cookie_cat_necessary: boolean;
	cookie_cat_functional: boolean;
	cookie_cat_marketing: boolean;
	cookie_cat_unclassified: boolean;
	cookie_cat_statistic: boolean;
};

const defaultConsent: CookieInformationConsents = {
	cookie_cat_necessary: false,
	cookie_cat_functional: false,
	cookie_cat_marketing: false,
	cookie_cat_unclassified: false,
	cookie_cat_statistic: false,
};

export const useCookieConsent = () => {
	const setCookieConsent = useCookieConsentState((state) => state.setCookieConsent); // Zustand selector

	useEffect(() => {
		if (isSSR) return;

		const handleConsentChange = () => {
			const consent = (window as any).cicc ?? defaultConsent;

			setCookieConsent({
				allowFunctional: consent.cookie_cat_functional,
				allowMarketing: consent.cookie_cat_marketing,
				allowNecessary: consent.cookie_cat_necessary,
				allowStatistics: consent.cookie_cat_statistic,
				allowUnclassified: consent.cookie_cat_unclassified,
			});
		};

		window.addEventListener('CookieInformationConsentGiven', handleConsentChange);

		handleConsentChange();

		return () => {
			window.removeEventListener('CookieInformationConsentGiven', handleConsentChange);
		};
	}, [setCookieConsent]);

	return useCookieConsentState();
};
