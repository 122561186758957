import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import { useCookie } from 'react-use';
import { ANONYMOUS_ID_COOKIE_NAME, DEVICE_ID_COOKIE_NAME, RAPTOR_COOKIE_NAME } from '~/features/tracking/constants';
import { useGaCookie } from '~/features/tracking/hooks/useGaCookie';
import { GtmGeneric } from '~/features/tracking/model';
import { pushToDataLayer } from '~/features/tracking/utils/pushToDataLayer';
import { useCookieConsent } from '~/shared/components/CookieConsent/hooks/useCookieConsent';
import { useFrame } from '~/shared/utils/frame/hooks/useFrame';
import { useMarket } from '~/shared/utils/market/hooks/useMarket';
import { isProduction, isSSR } from '~/shared/utils/platform/utils/platform';
import { usePage } from '~/templates/pages/hooks/usePage';
import { useBasket } from '../../basket/hooks/useBasket';

/**
 * Not really UUID, use with caution.
 * In theory, it's 1 in 10^48 chances of collision, in practice Math.random is not that good but good enough
 */
const getPseudoUUID = () => `${Math.random().toString(36).substring(2)}-${Math.random().toString(36).substring(2)}-${Math.random().toString(36).substring(2)}`;

function uuid() {
	return crypto.randomUUID ? crypto.randomUUID() : getPseudoUUID();
}

let isTracked = false;
let originalLocation: string;

export const useGenericTracking = () => {
	const page = usePage();
	const router = useRouter();
	const { data: frame } = useFrame();
	const { market, culture } = useMarket();
	const { allowStatistics } = useCookieConsent();
	const { data: basket } = useBasket();
	const [raptorCookie, setRaptorCookie] = useCookie(RAPTOR_COOKIE_NAME);
	const [anonymousIdCookie, setAnonymousIdCookie] = useCookie(ANONYMOUS_ID_COOKIE_NAME);
	const [deviceIdCookie, setDeviceIdCookie] = useCookie(DEVICE_ID_COOKIE_NAME);
	const gaCookie = useGaCookie();

	const track = useCallback(
		(pagePath: string) => {
			pushToDataLayer<GtmGeneric>({
				countrySite: `${culture}-${market}`,
				bu: 'rosendahl',
				environment: isProduction && frame.environment === 'production' ? 'live' : 'dev',
				solution: 'webshop',
				pageType: page.type,
				login: 'false', // TODO: implement tracking of logged in state
				clientID: gaCookie?.substr(6) || '',
				raptorCookieID: deviceIdCookie || '',
				anonymousID: basket?.id?.toString() || '',
				originalLocation,
				pagePath,
			});
		},
		[originalLocation, page.type, gaCookie, deviceIdCookie, culture, market, basket],
	);

	useEffect(() => {
		if (isSSR) {
			return;
		}

		if (!anonymousIdCookie) {
			// We set the anonymous id cookie for each session.
			// Options parameter with expiresAt property omitted to create a session cookie.
			setAnonymousIdCookie(uuid());
		}

		if (!allowStatistics || deviceIdCookie) {
			return;
		}

		setDeviceIdCookie(uuid(), {
			expires: 5 * 365, // set expiration date 5 years in the future, to prevent expiration
		});
	}, [allowStatistics]);

	useEffect(() => {
		if (isSSR) {
			return;
		}

		router.events.on('routeChangeComplete', track);

		return () => {
			router.events.off('routeChangeComplete', track);
		};
	}, [track]);

	useEffect(() => {
		if (isSSR || isTracked || !anonymousIdCookie) {
			// We do not want to track the initial data layer twice,
			// nor do we want to track before anonymousID cookie is set.
			return;
		}

		originalLocation = window.location.href;
		track(router.asPath);
		isTracked = true;
	}, [anonymousIdCookie, track]);

	useEffect(() => {
		if (isSSR || !allowStatistics) {
			return;
		}

		const reaId = router.query.reaId;

		if (reaId && typeof reaId === 'string' && reaId !== raptorCookie) {
			setRaptorCookie(reaId);
		}
	}, [router.query, allowStatistics]);
};
