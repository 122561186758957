import NextImage from 'next/image';
import { CSSProperties, memo } from 'react';
import ImageMagnifier from '~/features/image-magnifier/ImageMagnifier';
import IImageMagnifier from '~/features/image-magnifier/ImageMagnifier.def';
import { IImageMedia, NextImageProps } from '~/lib/data-contract';
import { getLoader } from '~/shared/components/ImageMedia/loaders/shared';

export type ImageMediaProps = Omit<NextImageProps, 'src' | 'alt' | 'sizes' | 'objectFit'> & {
	image: IImageMedia;
	sizes: Required<NextImageProps>['sizes'];
	objectFit?: 'contain' | 'cover';
	magnifier?: IImageMagnifier['type'];
	magnifyOnClick?: boolean;
};

/**
 * Image component for loading any image that comes from BE
 * It'll use a specific loader for each case
 * In the cases that the asset needs to be loaded from node server and the loader should be bypassed use plain next/image
 */
export const ImageMedia = memo(({ image: { origin, alt, src, focalY, focalX }, objectFit, style = {}, magnifier, magnifyOnClick, ...nextImageProps }: ImageMediaProps) => {
	const loader = getLoader(origin);

	let imageProps: Partial<NextImageProps> = {
		...nextImageProps,
	};

	const imageStyles: CSSProperties = {
		...style,
		maxWidth: '100%',
		objectFit,
	};

	if (typeof focalY === 'number' && typeof focalX === 'number') {
		imageProps = {
			...imageProps,
			fill: true,
		};

		imageStyles.objectPosition = `${focalX * 100}% ${focalY * 100}%`;
		imageStyles.objectFit = 'cover';
	}

	return (
		<>
			{magnifier ? (
				<ImageMagnifier
					imageUrl={src}
					alt={alt || ''}
					loader={loader}
					imageProps={imageProps}
					imageStyles={imageStyles}
					type={magnifier}
					activateOnClick={magnifyOnClick}
					focal={{
						x: typeof focalX === 'number' ? focalX : undefined,
						y: typeof focalY === 'number' ? focalY : undefined,
					}}
				/>
			) : (
				<NextImage
					loader={loader}
					alt={alt || ''}
					src={src}
					{...imageProps}
					style={imageStyles}
				/>
			)}
		</>
	);
});
