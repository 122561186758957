import create from 'zustand';

type CookieConsentState = {
	allowFunctional: boolean;
	allowNecessary: boolean;
	allowMarketing: boolean;
	allowUnclassified: boolean;
	allowStatistics: boolean;
};

type CookieConsentActions = {
	setCookieConsent: (cookieConsent: CookieConsentState) => void;
};

export const useCookieConsentState = create<CookieConsentState & CookieConsentActions>((set) => ({
	allowFunctional: false,
	allowNecessary: false,
	allowMarketing: false,
	allowUnclassified: false,
	allowStatistics: false,
	setCookieConsent: (cookieConsent: CookieConsentState) => set({ ...cookieConsent }),
}));
